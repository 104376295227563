import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import Countdown from "react-countdown";
import * as nearAPI from 'near-api-js';

import CardModal from '../CardModal';

import 'swiper/scss';
import "swiper/css/effect-fade";

import logo from '../../../assets/images/WL/logo.png'

import transBack from '../../../assets/images/pfp/trans-back.png'

import PrelimsImg1 from '../../../assets/images/edmond/prelims.jpg'
import MainEventImg1 from '../../../assets/images/edmond/main-event.jpg'

import { useSelector } from 'react-redux';

const LiveAuction = () => {
    const [data, setData] = useState(
        [
            {
                img: [PrelimsImg1],
                title: "LIVE STREAM VR/ 360 | Saturday June 11 2022 🥊 PRO KICKBOXING CANADA VS FRANCE: Guest Pass",
                nameAuthor: "iFeel & NearHUB",
                launch: (new Date(Date.UTC('2022', '03', '10', '16', '20', '0'))),
                selected: 1,
                prevSelected: 1,
                url: '/live-stream-vr-360-guest',
                available: 200,
                sold: '-'
            },            
            {
                img: [MainEventImg1],
                title: "VIP LIVE STREAM VR/ 360 | Saturday June 11 2022 🥊 PRO KICKBOXING CANADA VS FRANCE",
                nameAuthor: "iFeel & NearHUB",
                launch: (new Date(Date.UTC('2022', '03', '20', '16', '20', '0'))),
                selected: 1,
                prevSelected: 1,
                url: '/vip-live-stream-vr-360',
                available: 500,
                sold: '-'
            }           
        ]
    )

    const [now] = useState(Date.now());
    const [modalShow, setModalShow] = useState(false);

    const nearWallet = useSelector(state => state.near.wallet);
    const nftContractWL   = useSelector(state => state.near.nftContract);    

    useEffect(() => {
        setInterval(() => {
            const newData = data.map(ele => {
                if(typeof ele.selected == 'number') {
                    ele.prevSelected = ele.selected;
                    ele.selected++;
                    if (ele.selected > ele.img.length) ele.selected = 1;
                } else {
                    ele.prevSelected = [...ele.selected];
                    ele.selected = ele.selected.map((ele2, ind2) => Math.floor(Math.random() * ele.img[ind2].length) + 1);
                }
                return ele;
            });

            setData(newData);
        }, 5000)
    }, []);

    useEffect(() => {
        if (nftContractWL) {
            nftContractWL.get_user_minted_quantity().then((qty) => {
                data[1].sold = qty;
                setData(data);                
            });            
            nftContractWL.get_free_minted_quantity().then((qty) => {
                data[0].sold = qty;
                setData(data);                
            });            
        }
    }, [nftContractWL, nearWallet]);

    return (
        <Fragment>
            <section className="tf-section live-auctions">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-live-auctions">
                                <h2 className="tf-title pb-20" style={{textAlign: 'center', width: '100%'}}>Events</h2>
                            </div>
                        </div>
                        <div className="col-md-12 flex nbc-collections">
                            {
                                data.map((item, index) => (                                       
                                    <div key={'item'+index} className="swiper-container show-shadow">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="slider-item">
                                                    <div className="sc-card-product">
                                                        <div className="card-media">
                                                            <Link to={item.url}>
                                                                {
                                                                    item.img.map((img, ind) => {
                                                                        if (typeof img == 'string') {
                                                                            return <img key={'img'+index+ind} src={(item.selected == (ind+1) || item.prevSelected == (ind+1) || ind == 0) ? img : transBack} alt={item.title} className={item.selected == (ind+1) ? 'img selected' : 'img'} />
                                                                        } else {
                                                                            return img.map((img2, ind2) => <img key={'img'+index+ind+ind2} 
                                                                                                                src={(item.selected[ind] == (ind2+1) || item.prevSelected[ind] == (ind2+1) || ind == 0) ? img2 : transBack} 
                                                                                                                alt={item.title} 
                                                                                                                className={item.selected[ind] == (ind2+1) ? 'img selected' : 'img'} />);
                                                                        }                                                                        
                                                                    })
                                                                }                                                                                                                                    
                                                            </Link>
                                                        </div>
                                                        <div className="card-title">
                                                            <h5><Link to={item.url}>{item.title}</Link></h5>                                                                    
                                                        </div>
                                                        <div className="meta-info">
                                                            <div className="author">
                                                                <div className="avatar" style={{borderRadius:0}}>
                                                                    <img src={logo} alt="Axies" />
                                                                </div>
                                                                <div className="info">
                                                                    <span>Creator</span>
                                                                    <h6>{item.nameAuthor}</h6>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }                         
                        </div>
                    </div>
                </div>
            </section>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Fragment>
    );
}


export default LiveAuction;
