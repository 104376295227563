import React from 'react';
import HeaderStyle2WL from '../components/header/HeaderStyle2WL';
import Footer from '../components/footer/Footer';
import LiveAuction from '../components/layouts/home-5/LiveAuction';

import 'swiper/scss';
import "swiper/css/effect-fade";
import "swiper/css/effect-coverflow";
import 'swiper/scss/pagination';
import "swiper/css/effect-creative";
import "swiper/css/navigation";

const Home = () => {
    return (
        <div className='home-5'>
            <HeaderStyle2WL />
            <LiveAuction />                        
            <Footer />
        </div>
    );
}

export default Home;
