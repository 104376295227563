import React from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";

import { ReactComponent as NearType } from '../../assets/images/icon/near_type.svg'

import NEAR from '../../constants/near';
import { useSelector } from 'react-redux';

const CardModalVIPLSGuest = (props) => {    
    const nearWallet = useSelector(state => state.near.wallet);

    const login = () => {
        nearWallet?.requestSignIn(
            NEAR.NFT_CONTRACT_NAME,
           'Hearhub & iFeel Sports'
        );
    }

    return (

        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>

            <div className="modal-body space-y-20 pd-40">
                <h3>Get Guest Pass</h3>                
                { nearWallet?.isSignedIn() && nearWallet.getAccountId() &&
                  <a onClick={props.onPurchase} className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Get Guest Pass">Get Guest Pass</a>
                }
                { nearWallet?.isSignedIn() !== true &&
                  <a onClick={login} className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Connect Wallet">Connect Wallet</a>
                }
            </div>
        </Modal>

    );
};

export default CardModalVIPLSGuest;
